import { defineStore } from 'pinia';

export const useGlobalStore = defineStore('global', {
    state: () => ({
        global: {},
        header: {},
        emailSubscription: {},
        footer: {},
        signInModal: {},
        resetPasswordModal: {},
        signUpModal: {},
        subscribeModal: {},
    }),
    actions: {
        update(params: any) {
            if (params) {
                this.global = params;
                if (params.content?.Header) {
                    this.header = params.content.Header[0];
                }

                if (params.content?.Footer) {
                    this.footer = params.content.Footer[0];
                }

                if (params.content?.SignInModal) {
                    this.signInModal = params.content.SignInModal[0];
                }

                if (params.content?.EmailSubscription) {
                    this.emailSubscription = params.content.EmailSubscription[0];
                }

                if (params.content?.SignUpModal) {
                    this.signUpModal = params.content.SignUpModal[0];
                }

                if (params.content?.SubscribeModal) {
                    this.subscribeModal = params.content.SubscribeModal[0];
                }

                if (params.content?.ResetPasswordModal) {
                    this.resetPasswordModal = params.content.ResetPasswordModal[0];
                }
            } else {
                console.error('Unable to get "global" store params');
            }
        },
    },
});
